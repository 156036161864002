import { useMemo } from 'react';

import { useSelector } from '@datagrid/state';

import { appStore, configStore } from '@/core/stores';

export const useAppInstanceId = () => {
	const tenant = useSelector(() => appStore.tenant.get());
	const config = useSelector(() => configStore.get());

	return useMemo(() => {
		if (!config.env_id) {
			return config.instance_id;
		}

		return `${tenant}-${config.cluster_id}-${config.env_id}`;
	}, [tenant, config.instance_id, config.cluster_id, config.env_id]);
};

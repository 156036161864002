import React from 'react';

import {
	Button,
	type ButtonProps,
	createStyles,
	Icon,
	Modal,
	Tooltip,
	UnstyledButton,
	useDisclosure,
} from '@tf/ui';

import { useEntityConnectionLabels } from '@/core/hooks';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { ConnectionForm, type ConnectionFormProps } from '@/components/shared/ConnectionForm';

const useStyles = createStyles(({ colors }) => ({
	iconButton: {
		color: colors.light[4],
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '.7rem',
		padding: '.25rem',
		borderRadius: '50%',
		'&:hover': {
			color: colors.brand[5],
			backgroundColor: colors.brand[0],
		},
	},
}));

interface Props extends ConnectionFormProps {
	label?: string;
	buttonKind?: 'default' | 'icon';
	buttonProps?: ButtonProps;
}

export const AddConnectionButton: React.FC<Props> = (props) => {
	const { classes } = useStyles();
	const connectionModal = useDisclosure();
	const connectionLabels = useEntityConnectionLabels(props.parentEntity.kind);
	const label = props.label || connectionLabels[props.connectionKind].singular;

	if (!useCurrentUserCapabilityCheck(UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA)) {
		return null;
	}

	return (
		<>
			{props.buttonKind === 'icon' ? (
				<Tooltip label={`Add new ${label}`} openDelay={500}>
					<UnstyledButton onClick={connectionModal.open} className={classes.iconButton}>
						<Icon.IconPlus size={16} />
					</UnstyledButton>
				</Tooltip>
			) : (
				<Button
					size="xs"
					fullWidth
					variant="light"
					leftSection={<Icon.IconPlus size={16} />}
					onClick={connectionModal.open}
					{...props.buttonProps}
				>
					Add {label}
				</Button>
			)}

			{connectionModal.isOpen && (
				<Modal
					size="500px"
					opened={connectionModal.isOpen}
					title={`Add ${label}`}
					onClose={connectionModal.close}
				>
					<ConnectionForm
						parentEntity={props.parentEntity}
						connectionKind={props.connectionKind}
						review={props.review}
						skipRedirect={props.skipRedirect}
						onSuccess={connectionModal.close}
					/>
				</Modal>
			)}
		</>
	);
};

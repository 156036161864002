import { useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';

import { getMonitoringAssociationsQuery } from '@/core/queries';

const monitoringAssociationsQueryOptions = (params: BackendTypes.EntityAssociationsGetParams) => {
	return {
		queryKey: ['associations', params],
		queryFn: () => getMonitoringAssociationsQuery(params),
		staleTime: 20 * 1000,
	};
};

export const useMonitoringAssociations = (params: BackendTypes.EntityAssociationsGetParams) => {
	return useQuery(monitoringAssociationsQueryOptions(params));
};

import React from 'react';

import { useSelector } from '@datagrid/state';
import { Navigate } from 'react-router-dom';

import { extractForms, getFormHash } from '@tf/shared';
import { T } from '@tf/utils';

import { useAccountParams, useSelectedAccount } from '@/core/hooks';
import { appStore } from '@/core/stores';

export const NavigateToFirstEntityFormRoute: React.FC = () => {
	const account = useSelectedAccount();
	const { connectionId, entityId, connectionKind } = useAccountParams();
	const formDefs = useSelector(() => appStore.defs.get().forms);

	const reviews = account.reviews.filter((r) => r.connectionKind === connectionKind);
	const review = reviews.find((r) => r.graphLinkId === connectionId);
	const entity = review?.listEntities.find((e) => e.graphNodeId === entityId);

	T.assertNotNullish(review, 'review not found');
	T.assertNotNullish(entity, 'entity not found');

	const forms = extractForms({
		formDefs,
		segmentIdentities: entity.listSegments.map((s) => s.SegmentIdentity),
	});

	const firstForm = forms[0];

	return <Navigate to={`${firstForm.name}?hash=${getFormHash(firstForm)}`} replace />;
};

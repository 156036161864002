import { queryOptions, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

export const entityLookupQueryOptions = (entityKinds: BackendTypes.EntityKind[], query: string) => {
	return queryOptions({
		queryKey: ['entities', 'lookup', entityKinds, query],
		queryFn: async () => {
			if (query.length === 0) {
				return [];
			}

			const result = await BackendClient.getInstance().entityLookup({ query: query }, { entityKinds });

			return (await result.json()) as BackendTypes.EntityLookupData;
		},
	});
};

export const useEntityLookupQuery = (entityKinds: BackendTypes.EntityKind[], query: string) => {
	return useQuery(entityLookupQueryOptions(entityKinds, query));
};

import React, { useEffect, useState } from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { Alert, createModal, Icon, Input, Select, Stack } from '@tf/ui';
import { T } from '@tf/utils';

import { useCreateClientCaseRequestMutation } from '@/core/api/caseRequests';
import { useAccountParams, useSelectedAccount } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { clientCaseKindOptions } from '@/components/cases/requestKind';
import { ModalFooter } from '@/components/shared';

import { extractUniqueIndividualEntities, isValidEmail } from './utils';

type FieldsState = {
	email: string;
	type: string;
	typeError: string;
	emailError: string;
	graphId: string;
	graphIdError: string;
};

const initialFieldsState = {
	email: '',
	emailError: '',
	type: '',
	typeError: '',
	graphId: '',
	graphIdError: '',
};

interface CreateCaseModalProps {
	close: () => void;
}

export const CreateCaseModal = createModal<CreateCaseModalProps>(({ close }) => {
	const createClientCaseMutation = useCreateClientCaseRequestMutation();
	const navigate = useNavigate();
	const { accountId } = useAccountParams();
	const [fieldsState, setFieldsState] = useState<FieldsState>(initialFieldsState);

	const { reviews } = useSelectedAccount();
	const applicantReview = reviews.find((r) => r.connectionKind === 'APPLICANT');
	T.assertNotNullish(applicantReview, 'no applicant review found');

	const isIdenfyAvailable = useSelector(() => appStore.isIdenfyAvailable?.get());
	const caseTypeSelectOptions = clientCaseKindOptions.filter(({ value }) =>
		isIdenfyAvailable ? value : value !== 'LIVENESS_CHECK'
	);

	const individualEntities = extractUniqueIndividualEntities(reviews);

	useEffect(() => {
		if (individualEntities.length === 1) {
			setFieldsState((prevState) => ({
				...prevState,
				graphId: String(individualEntities[0].graphNodeId),
			}));
		}
	}, [individualEntities.length]);

	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();

				if (!fieldsState.type) {
					setFieldsState((prevState) => ({ ...prevState, typeError: 'Empty type' }));
				}

				if (!isValidEmail(fieldsState.email)) {
					setFieldsState((prevState) => ({ ...prevState, emailError: 'Invalid email' }));
				}

				if (fieldsState.type === 'LIVENESS_CHECK' && !fieldsState.graphId) {
					setFieldsState((prevState) => ({ ...prevState, graphIdError: 'Empty graph ID' }));
				}

				if (
					!fieldsState.type ||
					!isValidEmail(fieldsState.email) ||
					(fieldsState.type === 'LIVENESS_CHECK' && !fieldsState.graphId)
				) {
					return;
				}

				await createClientCaseMutation.mutateAsync(
					{
						accountId,
						clientEmail: fieldsState.email.trim(),
						requestKind: fieldsState.type as BackendTypes.ClientCaseRequestKind,
						livenessCheckEntityId:
							fieldsState.type === 'LIVENESS_CHECK' ? Number(fieldsState.graphId) : undefined,
					},
					{
						onSuccess: (res) => {
							close();
							setFieldsState(initialFieldsState);
							navigate(res.uuid);
						},
					}
				);
			}}
		>
			<Stack p="1rem">
				<Select
					required
					label="Case type"
					placeholder="Select case type"
					data={caseTypeSelectOptions}
					value={fieldsState.type}
					error={Boolean(fieldsState.typeError)}
					onChange={(type) => {
						if (!type) return;
						setFieldsState((prevState) => ({ ...prevState, type, typeError: '' }));
					}}
				/>

				{fieldsState.type === 'LIVENESS_CHECK' && (
					<Select
						required
						label="Individual entity"
						variant="default"
						placeholder="Select account"
						value={fieldsState.graphId}
						error={Boolean(fieldsState.graphIdError)}
						onChange={(graphId) => {
							if (!graphId) return;
							setFieldsState((prevState) => ({ ...prevState, graphId, graphIdError: '' }));
						}}
						data={individualEntities.map((e) => ({ value: String(e.graphNodeId), label: e.name }))}
					/>
				)}

				{fieldsState.type === 'LIVENESS_CHECK' && individualEntities.length === 0 && (
					<Alert
						icon={<Icon.IconAlertCircle />}
						color="dark"
						sx={{
							backgroundColor: `rgba(246, 102, 6, 0.2)`,
							borderColor: `rgba(246, 102, 6, 0.5)`,
							color: `rgb(246, 102, 6)`,
						}}
						fw="600"
					>
						Please add at least one individual entity to your account to proceed
					</Alert>
				)}

				<Input.Wrapper required label="Email">
					<Input
						error={fieldsState.emailError}
						placeholder="Enter email"
						value={fieldsState.email}
						onChange={(e) => {
							const email = e.target.value;
							setFieldsState((prevState) => ({ ...prevState, email, emailError: '' }));
						}}
					/>
				</Input.Wrapper>
			</Stack>
			<ModalFooter
				submitText={'Create'}
				isDisabled={createClientCaseMutation.isPending}
				onCancel={close}
			/>
		</form>
	);
});

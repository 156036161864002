import { useAccountParams, useSelectedAccount } from '@/core/hooks';

import { EntityWidget } from './EntityWidget';

export const Menu = () => {
	const { reviews } = useSelectedAccount();
	const { connectionId, connectionKind, entityId } = useAccountParams();

	const review = reviews.find((r) => r.graphLinkId === connectionId);
	const entity = review?.listEntities.find((e) => e.graphNodeId === entityId);
	if (!entity || !review) {
		throw new Error('Entity not found');
	}

	return (
		<EntityWidget
			entity={entity}
			connectionKind={connectionKind}
			review={review}
			fromGraphId={entityId}
		/>
	);
};

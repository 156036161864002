import React from 'react';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { Box, createStyles, TFCard, Title } from '@tf/ui';

import { FormCommentsButton } from './FormCommentsButton';

const useStyles = createStyles(({ colors }) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: colors.light[2],
		padding: '.75rem 1rem',
	},
	title: {
		flex: 1,
		display: 'flex',
		fontSize: '1.2rem',
		color: colors.gray[7],
		fontWeight: 500,
	},
}));

interface Props extends React.PropsWithChildren {
	def: ExtendedFormDefinition<BackendTypes.FormDefinition>;
}

export const FormWrapper: React.FC<Props> = ({ children, def }) => {
	const { classes } = useStyles();

	return (
		<Box>
			<TFCard>
				<Box className={classes.header}>
					<Title className={classes.title}>{def.label}</Title>
					<FormCommentsButton formId={def.name} graphId={def.segmentIdentities[0].graphId} />
				</Box>
			</TFCard>
			{children}
		</Box>
	);
};

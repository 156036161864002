import React from 'react';

import { Box, createStyles, TFText } from '@tf/ui';

const useStyles = createStyles(({ colors }) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '0 .5rem .5rem',
	},
	label: {
		cursor: 'default',
		userSelect: 'none',
		fontSize: '.7rem',
		letterSpacing: '.04rem',
		textTransform: 'uppercase',
		fontWeight: 600,
		color: colors.light[4],
	},
	requiredMark: {
		marginLeft: '.125rem',
	},
}));

interface Props {
	label: string;
	isRequired: boolean;
	actions?: React.ReactNode;
}

export const GroupLabel: React.FC<Props> = ({ label, isRequired, actions }) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.root}>
			<TFText className={classes.label} lineClamp={1}>
				{label}
				{isRequired ? (
					<span className={classes.requiredMark} title="Required">
						*
					</span>
				) : null}
			</TFText>
			<Box>{actions}</Box>
		</Box>
	);
};

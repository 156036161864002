import React from 'react';

import type { PropsWithChildren } from 'react';

import { Box, createStyles, TFCard, TFText } from '@tf/ui';

import { useOverviewAccount } from '@/core/api/overview';
import { useAccountInfoSegments, useSelectedAccount } from '@/core/hooks';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { AssignedManagersWidget } from '@/components/shared/AssignedManagers';
import { DateValue } from '@/components/shared/DateValue';

import { AccountStatus } from './AccountStatus';
import { LatestReviewDate } from './LatestReviewDate';
import { NextReviewDate } from './NextReviewDate';
import { ReviewStatus } from './ReviewStatus';
import { RiskProfile } from './RiskProfile';

const useStyles = createStyles(({ colors, fontSizes, radius }) => {
	const borderColor = colors.light[3];

	return {
		wrapper: {
			paddingTop: '0.5rem',
			paddingBottom: '0.5rem',
			display: 'flex',
			borderRadius: radius.sm,
			minHeight: '4.7rem',

			'& > :not(:first-of-type)': {
				borderLeft: `1px solid ${borderColor}`,
			},
		},
		item: {
			paddingLeft: '0.75rem',
			paddingRight: '0.75rem',
			minWidth: '132px',
		},
		itemTitle: {
			color: colors.gray[5],
			fontWeight: 600,
			fontSize: fontSizes.xs,
			letterSpacing: '.5px',
		},
	};
});

const Item: React.FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.item}>
			<TFText pb="0.15rem" className={classes.itemTitle}>
				{label}
			</TFText>
			{children}
		</Box>
	);
};

export const AccountToolbar: React.FC = () => {
	const { classes } = useStyles();

	const canReviewAccounts = useCurrentUserCapabilityCheck(UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA);

	const {
		accountStatus,
		onboardingDate,
		closingDate,
		clientRiskLevel,
		kycApprovalDate,
		kycApprovalDateMutation,
		nextKycReviewDate,
		nextKycReviewDateMutation,
	} = useAccountInfoSegments();

	const wasActivated = accountStatus && accountStatus !== 'PROSPECT' && accountStatus !== 'REJECTED';
	const isActive = accountStatus === 'ACTIVE' || accountStatus === 'SUSPENDED';
	const isClosed = accountStatus === 'CLOSED';
	const { meta } = useSelectedAccount();
	const accountId = meta.graphNodeId;
	const { data } = useOverviewAccount(accountId);
	const listUsers = data?.listUsers;

	const assignedManagers = data?.account.assignedManagers ?? [];

	return (
		<TFCard>
			<Box className={classes.wrapper}>
				<Item label="Account status">
					<AccountStatus />
				</Item>

				<Item label="Review status">
					<ReviewStatus />
				</Item>

				<Item label="Risk profile">
					<RiskProfile clientRiskLevel={clientRiskLevel} readonly={isClosed || !canReviewAccounts} />
				</Item>

				{wasActivated ? (
					<Item label="Onboarding date">
						<DateValue value={onboardingDate} />
					</Item>
				) : null}

				{wasActivated ? (
					<Item label="Latest review">
						{isActive && canReviewAccounts ? (
							<LatestReviewDate
								date={kycApprovalDate}
								isLoading={kycApprovalDateMutation.isPending}
								onSave={kycApprovalDateMutation.mutateAsync}
							/>
						) : (
							<DateValue value={kycApprovalDate} />
						)}
					</Item>
				) : null}

				{isActive ? (
					<Item label="Next review">
						{canReviewAccounts ? (
							<NextReviewDate
								baseDate={kycApprovalDate}
								date={nextKycReviewDate}
								isLoading={nextKycReviewDateMutation.isPending}
								onSave={nextKycReviewDateMutation.mutateAsync}
							/>
						) : (
							<DateValue value={nextKycReviewDate} />
						)}
					</Item>
				) : null}

				{isClosed ? (
					<Item label="Closing date">
						<DateValue value={closingDate} />
					</Item>
				) : null}

				<Item label="Assigned managers">
					<AssignedManagersWidget
						accountId={accountId}
						selectedIds={assignedManagers}
						managers={listUsers ?? []}
					/>
				</Item>
			</Box>
		</TFCard>
	);
};

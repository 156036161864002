import { Outlet } from 'react-router-dom';

import { SideMenuLayout } from '@/components/shared';

import { Menu } from './Menu';

export const EntityLayout = () => {
	return (
		<SideMenuLayout menu={<Menu />}>
			<Outlet />
		</SideMenuLayout>
	);
};

import React from 'react';

import { SignIn, useAuth } from '@clerk/clerk-react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { TFNotFoundRoute } from '@tf/shared';
import { Center } from '@tf/ui';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { AccountOverview } from '@/components/accounts';
import { ClientCasesTable, EditClientCase } from '@/components/cases';
import { AutoAssignedSettings, CraSettingsTable } from '@/components/cra-settings';
import { MonitoringCardAssociations, MonitoringCardMatches } from '@/components/monitoring';
import { ReviewForm } from '@/components/reviews';
import { UserInvitationsTable, UserTable } from '@/components/settings';
import { AppLayout } from '@/components/shared';
import { AcceptInvitationRoute } from '@/routes/AcceptInvitationRoute';
import AccountListRoute from '@/routes/AccountListRoute';
import { CRASettingsLayout } from '@/routes/CRASettingsLayout';
import { DataExportRoute } from '@/routes/DataExportRoute';
import InstantCheckRoute from '@/routes/InstantCheckRoute';
import { AccountCardLayout } from '@/routes/layouts/AccountCardLayout';
import { AccountReviewLayout } from '@/routes/layouts/AccountReviewLayout';
import { AccountTabsLayout } from '@/routes/layouts/AccountTabsLayout';
import { EntityLayout } from '@/routes/layouts/EntityLayout';
import {
	InstantCheckCardLayout,
	InstantCheckHitsLayout,
	InstantCheckSearchLayout,
} from '@/routes/layouts/InstantCheckLayout';
import {
	MonitoringCardLayout,
	MonitoringCardTabsLayout,
	MonitoringMatchLayout,
} from '@/routes/layouts/MonitoringLayout';
import { NavigateToFirstEntityFormRoute } from '@/routes/layouts/NavigateToFirstEntityFormRoute';
import { NavigateToFirstFormRoute } from '@/routes/layouts/NavigateToFirstFormRoute';
import { UserTablesLayout } from '@/routes/layouts/UserTablesLayout';
import { MonitoringRoute } from '@/routes/MonitoringRoute';
import { NoPermissionsRoute } from '@/routes/NoPermissionsRoute';
import { UserCardRoute } from '@/routes/UserCardRoute';
import { UserInvitationRoute } from '@/routes/UserInvitationRoute';

const AuthRouteGuard: React.FC<{
	allowWhen: (isSignedIn: boolean) => boolean;
	redirectTo: string;
	layoutWrapper?: React.ComponentType;
}> = ({ allowWhen, redirectTo, layoutWrapper: LayoutWrapper }) => {
	const { isSignedIn } = useAuth();
	T.assertNotNullish(isSignedIn, 'isSignedIn is undefined');

	if (!allowWhen(isSignedIn)) {
		return <Navigate to={redirectTo} />;
	}

	return LayoutWrapper ? <LayoutWrapper /> : <Outlet />;
};

export const Router: React.FC = () => {
	const state = appStore.state.get();

	return (
		<BrowserRouter>
			<Routes>
				<Route path="accept-invite" element={<AcceptInvitationRoute />} />
				{/*non-authenticated users*/}
				<Route
					path="/"
					element={<AuthRouteGuard allowWhen={(isSignedIn) => !isSignedIn} redirectTo="/" />}
				>
					<Route
						path="/sign-in/*"
						element={
							<Center mt="20vh">
								<SignIn routing="path" path="/sign-in" forceRedirectUrl={window.location.origin} />
							</Center>
						}
					/>
					<Route path="*" element={<Navigate to="/sign-in" replace />} />
				</Route>

				{/* no permission routes */}
				{state === 'no_permissions' && (
					<Route path="/">
						<Route path="*" element={<NoPermissionsRoute email="support@trustform.io" />} />
						<Route index element={<NoPermissionsRoute email="support@trustform.io" />} />
					</Route>
				)}

				{/* authenticated routes */}
				<Route
					path="/"
					element={
						<AuthRouteGuard
							allowWhen={(isSignedIn) => Boolean(isSignedIn)}
							redirectTo="/sign-in"
							layoutWrapper={AppLayout}
						/>
					}
				>
					<Route index element={<AccountListRoute />} />

					<Route path="settings/users" element={<UserTablesLayout />}>
						<Route index element={<UserTable />} />
						<Route path="invitations" element={<UserInvitationsTable />} />
					</Route>

					<Route path="settings/cra" element={<CRASettingsLayout />}>
						<Route index element={<CraSettingsTable />} />
						<Route path="auto-assigned" element={<AutoAssignedSettings />} />
					</Route>

					<Route path="settings/invitations/invite" element={<UserInvitationRoute />} />
					<Route path="settings/users/:userId" element={<UserCardRoute />} />
					<Route path="accounts/:accountId" element={<AccountCardLayout />}>
						<Route element={<AccountTabsLayout />}>
							<Route index element={<AccountOverview />} />
							<Route index path="cases" element={<ClientCasesTable />} />
							<Route index path="cases/:id" element={<EditClientCase />} />
							<Route path=":connectionKind" element={<AccountReviewLayout />}>
								<Route index element={<NavigateToFirstFormRoute />} />
								<Route
									path=":connectionId/:formKind"
									element={<ReviewForm />}
									shouldRevalidate={() => true}
								/>
							</Route>
						</Route>
						<Route path=":connectionKind/:connectionId/entity/:entityId" element={<EntityLayout />}>
							<Route index element={<NavigateToFirstEntityFormRoute />} />
							<Route path=":formKind" element={<ReviewForm />} shouldRevalidate={() => true} />
						</Route>
					</Route>

					<Route path="data-export" element={<DataExportRoute />} />

					<Route path="instant-check" element={<InstantCheckRoute />}>
						<Route index element={<InstantCheckSearchLayout />} />
						<Route path="hits" element={<InstantCheckHitsLayout />} />
						<Route path="card" element={<InstantCheckCardLayout />} />
					</Route>

					<Route path="monitoring">
						<Route index element={<MonitoringRoute />} />
						<Route path=":graphId" element={<MonitoringCardLayout />}>
							<Route element={<MonitoringCardTabsLayout />}>
								<Route index element={<MonitoringCardMatches />} />
								<Route path="associations" element={<MonitoringCardAssociations />} />
							</Route>
							<Route path="matches/:matchId" element={<MonitoringMatchLayout />} />
						</Route>
					</Route>
				</Route>

				<Route path="*" element={<TFNotFoundRoute />} />
			</Routes>
		</BrowserRouter>
	);
};

import type { BackendTypes } from '@tf/api';

export const clientCaseKindLabels: Record<BackendTypes.ClientCaseRequestKind, string> = {
	LIVENESS_CHECK: 'Identity verification',
	FREEFORM: 'Information request',
};

export const clientCaseKindOptions = Object.entries(clientCaseKindLabels).map(([value, label]) => ({
	value,
	label,
}));

import React, { useEffect } from 'react';

import { useObserveEffect, useSelector } from '@datagrid/state';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { BackendClient } from '@tf/api';
import { TFLoadingOverlay, TFNotifier } from '@tf/ui';

import { monitoringStore } from '@/core/stores';
import type { AccountWithReviews } from '@/core/types/account';

const useEntityQuery = (entityId: number) =>
	useQuery<AccountWithReviews>({
		queryKey: ['monitoring-entity', entityId],
		queryFn: async () => {
			const api = BackendClient.getInstance();
			return api.entityGet(entityId).then((res: Response) => res.json());
		},
	});

interface Props extends React.PropsWithChildren {
	entityId: number;
}

export const MonitoringProvider: React.FC<Props> = ({ children, entityId }) => {
	const navigate = useNavigate();

	const entityQuery = useEntityQuery(entityId);
	const selectedAccount = useSelector(() => monitoringStore.selected.get());

	useObserveEffect(() => {
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		monitoringStore.set({ hits: null, selected: null });
	});

	useEffect(() => {
		if (selectedAccount === null) {
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			monitoringStore.selected.set(entityQuery.data || null);
		} else {
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			monitoringStore.selected.assign(entityQuery.data || null);
		}
	}, [entityQuery.data]);

	useEffect(() => {
		if (entityQuery.error) {
			TFNotifier.error('Could not read the entity information. Please try again later');
			navigate('/monitoring');
		}
	}, [entityQuery.error, navigate]);

	if (selectedAccount === null) {
		return <TFLoadingOverlay label="Loading..." h={500} />;
	}

	return <>{children}</>;
};
